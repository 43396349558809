





































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'Mobile',
  setup(_, { root: { $router: router } }) {
    const drawer = ref(false)
    const handleDrawerRouter = (name: string) => {
      router.push({ name })
    }
    return {
      drawer,
      handleDrawerRouter,
    }
  },
})
