import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Mobile from '../views/Mobile.vue'
import { mobileCheck } from '@/utils'
import { provide, inject, reactive } from '@vue/composition-api'
import { getToken } from '@/utils'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const tempPush = function push(
  this: any,
  location: any,
  onResolve: any,
  onReject: any,
) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return (originalPush.call(this, location) as any).catch((err: any) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

VueRouter.prototype.push = tempPush as any

const desktopRouter = ['Index', 'Home', 'Login', 'Signup', 'OverView', 'Dialog']
const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
  },
  {
    path: '/graph',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/signup.vue'),
  },
  {
    path: '/overview',
    name: 'OverView',
    component: () => import('../views/OverView.vue'),
  },
  {
    path: '/dialog',
    name: 'Dialog',
    component: () => import('../views/Dialog.vue'),
  },
  {
    path: '/mobile',
    component: Mobile,
    children: [
      {
        path: '/',
        name: 'MobileHome',
        component: () => import('../views/MobileHome.vue'),
      },
      {
        path: 'search',
        name: 'MobileSearch',
        component: () => import('../views/MobileSearch.vue'),
      },
      {
        path: 'dialog',
        name: 'MobileDialog',
        component: () => import('../views/MobileDialog.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, _, next) => {
  desktopRouter.findIndex(v => v === to.name) >= 0 && mobileCheck()
    ? next({ name: 'MobileHome' })
    : next()
})

router.beforeEach((to, _, next) => {
  if (to.name === 'Signup' && getToken()) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

const RouterSymbol = Symbol()

export function provideRouter() {
  provide(RouterSymbol, reactive(router))
}

export function useRouter() {
  const router = inject(RouterSymbol)
  if (!router) {
    throw 'no router instance'
  }
  return router as VueRouter
}

export default router
