<template>
  <div class="body-contanier">
    <div id="header" class="header">
      <v-container>
        <v-row>
          <div class="text-container">
            <h1 class="h1-heading">知识图谱平台</h1>
            <div class="h1-bottom"></div>
            <p class="text-p">
              应用知识图谱技术实现知识抽取和业务建模等应用，支持百亿以上的实体和关系管理，基于业务场景对数据进行深度关联分析，可广泛应用于公安侦查、金融风控、营销管理和企业综合应用等场景。
            </p>
            <div v-if="!getToken()">
              <a class="btn-solid-lg" @click="gotoMenu('signup')">注册</a>
              <a class="btn-solid-lg" @click="gotoMenu('login')">登录</a>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>
    <div class="img1"></div>
    <div class="img2"></div>
    <div class="img5"></div>
    <div id="features" class="tabs1">
      <v-container>
        <div class="col-lg-12">
          <!-- <div class="above-heading">产品特色</div> -->
          <h2 class="h2-heading">产品特色</h2>
          <div class="h2-bottom"></div>
        </div>
        <v-row class="row-container">
          <v-col cols="2">
            <v-img
              src="../assets/images/description-1.png"
              height="4rem"
              width="4rem"
            ></v-img>
          </v-col>
          <v-col cols="3">
            <h1 class="row-title">精准的语义理解</h1>
          </v-col>
          <v-col cols="7">
            <p class="row-text">
              基于先进语义算法的自然语言处理能力，对于语义理解更精准
            </p>
          </v-col>
        </v-row>
        <v-row class="row-container">
          <v-col cols="2">
            <v-img
              src="../assets/images/description-2.png"
              height="4rem"
              width="4rem"
            ></v-img>
          </v-col>
          <v-col cols="3">
            <h1 class="row-title">响应速度快</h1>
          </v-col>
          <v-col cols="7">
            <p class="row-text">
              底层存储基于图数据库等多种类型数据库，可快速导入百亿数据，实现毫秒级查询响应
            </p>
          </v-col>
        </v-row>
        <v-row class="row-container">
          <v-col cols="2">
            <v-img
              src="../assets/images/description-3.png"
              height="6.25rem"
              width="4rem"
            ></v-img>
          </v-col>
          <v-col cols="3">
            <h1 class="row-title">强悍的挖掘能力</h1>
          </v-col>
          <v-col cols="7">
            <p class="row-text">
              支持实体间更深关系的挖掘，提供更加广泛有效的信息
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <div class="img3"></div>
      <div class="img4"></div>
    </div>
    <div id="services" class="tabs2">
      <div class="services-container">
        <h2 class="h2-heading">产品应用</h2>
        <div class="h2-bottom"></div>
        <v-row>
          <v-col v-for="i in 5" :key="i">
            <div class="col-container">
              <v-img
                :src="require('../assets/images/features-' + i + '.png')"
                style="margin: auto;max-width: 8rem;max-height:10rem ;"
              ></v-img>
              <h1 class="col-title">{{ title[i] }}</h1>
              <p class="col-text">{{ text[i] }}</p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <div class="img6"></div>
      <div class="img7"></div>
    </div>
    <div class="footer">
      <v-container>
        <v-row>
          <v-col cols="5">
            <h4>关于平台</h4>
            <p>
              知识图谱平台提供各种数据关系的搜索与展示
            </p>
          </v-col>
          <v-col cols="5">
            <h4>相关链接</h4>
            <p>Our business partners</p>
          </v-col>
          <v-col cols="2">
            <h4>联系方式</h4>
            <p>demo@demo.com</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="copyright">
      <p class="p-small">
        Copyright © 2020
        <a>知识图谱平台</a>
      </p>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils'
export default {
  name: 'Index',
  data() {
    return {
      title: [
        '',
        '信息检索',
        '自然语言理解',
        '推荐系统',
        '公安刑侦',
        '司法辅助',
      ],
      text: [
        '',
        '搜索引擎中对实体信息的精准聚合和匹配、对关键词的理解以及对搜索意图的语义分析等',
        '知识图谱中的知识作为理解自然语言中实体和关系的背景信息',
        '知识图谱提供了实体与实体之间更深层次、更长范围的关联，增强了推荐算法的挖掘能力，一定程度上提高了推荐的准确性和多样性，也有效地弥补交互信息的稀疏或缺失',
        '综合利用公安业务数据，以时、地、人、事、物和组织等要素进行建模，实现综合分析研判、管控预警、线索挖掘和团伙发现等业务应用',
        '法律条文的结构化表示和查询来辅助案件的判决',
      ],
    }
  },
  methods: {
    gotoMenu(ref) {
      if (ref === 'signup') {
        this.$router.push('/Signup')
      } else if (ref === 'login') {
        this.$router.push('/login')
      }
    },
    getToken() {
      return getToken() ? true : false
    },
  },
}
</script>

<style scoped>
.body-contanier {
  position: relative;
  z-index: 2;
  background-color: #e5e5e5;
  width: 100%;
}
.cls-1 {
  fill: #14213d;
}
.cls-2 {
  fill: #fca311;
}
h2 {
  color: #333;
  font: 700 2rem/2.625rem 'Open Sans', sans-serif;
  letter-spacing: -0.2px;
}
.header {
  background-color: #e5e5e5;
  padding: 17.25rem 10rem 0rem 8.75rem;
  width: 100%;
}
.text-container {
  margin-bottom: 2rem;
  width: 100%;
}
.h1-heading {
  margin-bottom: 1rem;
  color: #14213d;
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 3rem;
  margin-left: 4.5%;
}
.h1-bottom {
  height: 1.25rem;
  background-color: #fca311;
  width: 60%;
  border-radius: 2rem;
  height: 1.75rem;
  margin-top: -0.625rem;
}
/* .h1-bottom:hover {
  margin-top: -6.5rem;
  height: 8rem;
} */
.text-p {
  font-family: 'Segoe UI';
  font-size: 1rem;
  letter-spacing: 36;
  line-height: 2.25rem;
  color: #14213d;
  width: 60%;
  font-weight: Regular;
}
.btn-solid-lg {
  display: inline-block;
  padding: 1.5rem 3.725rem 1.5rem 3.725rem;
  border: 0.15rem solid #fca311;
  border-radius: 2rem;
  background-color: #e5e5e5;
  color: #fca311 !important;
  font: 500 1.25rem/0 'Open Sans', sans-serif;
  text-decoration: none;
  transition: all 0.2s;
  margin-right: 4rem;
}
.btn-solid-lg:hover {
  background-color: #fca311;
  color: #ffffff !important;
  text-decoration: none;
}
.text-container {
  margin-bottom: 4rem;
  margin-right: 0.15rem;
}
.tabs1 {
  position: absolute;
  z-index: 2;
  top: 60rem;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #e5e5e5;
}
.tabs2 {
  position: absolute;
  z-index: 2;
  top: 125rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #e5e5e5;
}
.p-heading {
  text-align: center;
}
.h2-heading {
  text-align: center;
  font-family: 'microsoft yahei';
  font-weight: 520;
  font-size: 3.75rem;
  color: #14213d;
  margin: auto;
}
.h2-bottom {
  margin: -0.1rem auto 5rem auto;
  background-color: #fca311;
  width: 27%;
  border-radius: 2rem;
  height: 1.125rem;
}
/* .h2-bottom:hover {
  margin-top: -3.75rem;
  height: 5rem;
} */
.row-container {
  padding: 1.75rem 0rem 1rem 4.725rem;
  border: 0.15rem solid #fca311;
  border-radius: 3.6rem;
  background-color: #e5e5e5;
  color: #14213d;
  font: 500 1.25rem/0 'microsoft yahei', sans-serif;
  text-decoration: none;
  transition: all 0.2s;
  margin: 0 auto 2.25rem auto;
  height: 10rem;
}
.row-container:hover {
  background-color: #fca311;
}
.row-title {
  margin-top: 1.6rem;
  margin-left: -1.75rem;
  font-size: 1.85rem;
  font-weight: 400;
  letter-spacing: 36;
  line-height: 1.0625rem;
}
.row-text {
  letter-spacing: 36;
  line-height: 1.8rem;
  margin-top: 1rem;
  font-weight: 400;
}
.services-container {
  margin-left: 8.75%;
  margin-right: 8.75%;
  /*  margin-bottom: 18.25rem; */
}
.col-container {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  height: auto;
  width: 100%;
  border: 0.15rem solid #fca311;
  border-radius: 15rem;
  background-color: #e5e5e5;
}
.col-title {
  font-size: 1.825rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.9375rem;
  color: #e4930f;
  text-align: center;
}
.col-text {
  font-size: 1.125rem;
  letter-spacing: 0;
  line-height: 2.25rem;
  margin-top: 1rem;
  font-weight: 400;
  font-family: 'microsoft yahei';
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  opacity: 70%;
}
.footer {
  position: absolute;
  z-index: 3;
  top: 211rem;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  background-color: #14213d;
}
.footer h4 {
  margin-bottom: 0.5rem;
  color: #e4930f;
  font-size: 2.125rem;
  font-weight: 600;
}
.footer p {
  color: #e4930f;
  font-size: 1.125rem;
  letter-spacing: 36;
  line-height: 36px;
}
.footer a {
  color: #f3f7fd !important;
}
.copyright {
  width: 100%;
  position: absolute;
  top: 224rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #14213d;
  text-align: center;
}
.p-small {
  padding-top: 3rem;
  border-top: 0.12rem solid #e4930f;
  width: 85%;
  color: #e4930f;
  font-size: 1.2rem;
  font-weight: 320;
  font-family: 'microsoft yahei';
  margin: auto;
}
.copyright a {
  font-size: 1.2rem;
  font-weight: 320;
  color: #e4930f !important;
  text-decoration: none;
}
.img1 {
  display: inline-block;
  position: absolute;
  z-index: 3;
  margin-top: -10rem;
  background: url('../assets/images/img1.png');
  background-repeat: no-repeat;
  background-position: -57.5rem -18.75rem;
  height: 49.5rem;
  width: 16.82%;
  transform: scaleX(0.9);
  transform: scaleY(0.85);
  object-fit: contain;
  border: none;
}
.img2 {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 0%;
  /* left: 72.5%; */
  top: 8rem;
  /* margin-top: -30rem; */
  background: url('../assets/images/img2.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  height: 77.5rem;
  width: 26rem;
  transform: scaleX(0.9);
  transform: scaleY(0.9);
  object-fit: contain;
  border: none;
}
.img3 {
  display: inline-block;
  position: absolute;
  z-index: 3;
  left: 0rem;
  top: 106rem;
  background: url('../assets/images/img3.png');
  background-repeat: no-repeat;
  background-position: -64.375rem -15.625rem;
  height: 40rem;
  width: 16rem;
  /* 16rem */
  transform: scaleX(0.9);
  transform: scaleY(0.9);
  object-fit: contain;
  border: none;
}
.img4 {
  display: inline-block;
  position: absolute;
  z-index: 3;
  top: 102rem;
  right: 0;
  background: url('../assets/images/img4.png');
  background-repeat: no-repeat;
  background-position: -16.25rem -20.625rem;
  height: 54rem;
  width: 16rem;
  transform: scaleX(0.8);
  transform: scaleY(0.8);
  object-fit: contain;
  border: none;
}
.img5 {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 20rem;
  background: url('../assets/images/img5.png');
  background-repeat: no-repeat;
  background-position: -6.25rem -22.5rem;
  height: 40rem;
  width: 20rem;
  transform: scaleX(0.9);
  transform: scaleY(0.9);
  object-fit: contain;
  border: none;
}
.img6 {
  display: inline-block;
  position: absolute;
  z-index: 4;
  top: 188.8rem;
  left: 0;
  background: url('../assets/images/img6.png');
  background-repeat: no-repeat;
  background-position: -53.125rem -3.125rem;
  height: 24rem;
  width: 100%;
  transform: scaleX(0.8);
  transform: scaleY(0.9);
  object-fit: contain;
  border: none;
}
.img7 {
  display: inline-block;
  position: absolute;
  right: 0;
  z-index: 3;
  top: 185rem;
  background: url('../assets/images/img7.png');
  background-repeat: no-repeat;
  background-position: -53.125rem -32.8125rem;
  height: 28rem;
  width: 55rem;
  /* 55rem */
  transform: scaleX(0.9);
  transform: scaleY(0.9);
  object-fit: contain;
  border: none;
}
</style>
