const graphDetails = {
  state: {
    summary: {
      isable: false,
      content: null,
    },
    isSummaryShow: false,
    isDetailsShow: false,
    clickedNode: null,
    centerTitle: '',
    centerList: {},
    spreadList: {},
    mobileRouterTitle: '',
  },
  mutations: {
    setSummary(state: { summary: any }, data: any) {
      state.summary = data
    },
    setisSummaryShow(state: { isSummaryShow: any }, data: any) {
      state.isSummaryShow = data
    },
    setisDetailsShow(state: { isDetailsShow: any }, data: any) {
      state.isDetailsShow = data
    },
    setclickNode(state: { clickedNode: any }, data: any) {
      state.clickedNode = data
    },
    setcenterTitle(state: { centerTitle: any }, data: any) {
      state.centerTitle = data
    },
    setcenterList(state: { centerList: any }, data: any) {
      state.centerList = data
    },
    setspreadList(state: { spreadList: any }, data: any) {
      state.spreadList = data
    },
    setMobileTitle(state: { mobileRouterTitle: any }, data: any) {
      state.mobileRouterTitle = data
    },
  },
}
export default graphDetails
