import Vue from 'vue'
import Vuex from 'vuex'
import graphDetails from './modules/graphDetails'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    graphDetails,
  },
})
