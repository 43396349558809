import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueParticles from 'vue-particles'
import VueCompositionAPI from '@vue/composition-api'
import '@/assets/iconfont/iconfont.css'
Vue.use(VueParticles)
Vue.use(VueCompositionAPI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
